.client-accueil {
  font-family: "Inter", sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: white;

  h1 {
    font-size: 3rem;
    font-weight: 700;
    background: linear-gradient(180deg, #898989 0%, #8000ff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  h2 {
    width: 35.75rem;
    margin-top: 0.5rem;
  }

  .scnd-p-accueil-client {
    color: #797d91;
    font-size: 1rem;
    font-weight: 300;
    margin-top: 1rem;
  }

  .title-houston {
    width: 35rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    p {
      color: #797d91;
      font-size: 1.125rem;
      font-weight: 600;
    }
  }

  .container-description-img {
    margin-top: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    .description-accueil {
    }

    .img-accueil {
      //   margin-left: 5rem;
    }
    .rocket-houston {
    }

    // .getstarted-button {
    //   margin-top: 3rem;
    //   background: linear-gradient(180deg, #8543c6 0%, #9846e9 100%);
    //   border-radius: 30px;
    //   width: 140px;
    //   height: 55px;
    //   color: white;
    //   border: none;
    //   font-size: 0.9rem;
    //   font-weight: 600;
    //   cursor: pointer;
    // }
  }

  .homepage-menu-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    column-gap: 27px;
    row-gap: 54px;
    margin-top: 3rem;
    padding-bottom: 3rem;
    justify-content: center;

    div {
      padding: 35px;
      width: 20rem;
      height: 20rem;
      border-radius: 10px;
      background-color: #fbfbfb;

      border: 1px solid #f1f1f1;
    }

    .block-homepage-client {
      cursor: pointer;
      transition: all 300ms;
      &:hover {
        box-shadow: 1.5px 2.5px 15px rgba(184, 184, 184, 0.2);
        transform: scale(1.05);
      }
      img {
        margin-top: 4px;
      }
      .title-block {
        color: $primary400;
        font-weight: 600;
        font-size: 2rem;
        margin-top: 1.813rem;
      }
      p {
        font-size: 1rem;
        margin-top: 0.938rem;
      }

      .setting-icon {
        color: $primary400;
        margin-top: 4px;
      }

      .myteam-icon {
        color: $primary400;
        margin-top: 4px;
      }

      .knowledge-title {
        color: $primary400;
        font-weight: 600;
        font-size: 1.92rem;
        margin-top: 1.813rem;
      }
    }

    .block-homepage-client-gray {
      img {
        margin-top: 4px;
        color: #b6b6b6;
      }
      h4 {
        color: #b6b6b6;
        font-weight: 600;
        font-size: 2rem;
      }
      p {
        color: #b6b6b6;
        font-size: 1rem;
        margin-top: 0.938rem;
      }
    }
  }

  // __________ RESPONSIVE ___________ //

  @media (max-width: 1300px) {
    .container-description-img {
      margin-top: 8vh;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      .description-accueil {
      }

      .img-accueil {
        //   margin-left: 5rem;
      }
      .rocket-houston {
        width: 15rem;
      }

      // .getstarted-button {
      //   margin-top: 3rem;
      //   background: linear-gradient(180deg, #8543c6 0%, #9846e9 100%);
      //   border-radius: 30px;
      //   width: 140px;
      //   height: 55px;
      //   color: white;
      //   border: none;
      //   font-size: 0.9rem;
      //   font-weight: 600;
      //   cursor: pointer;
      // }
    }

    .homepage-menu-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      align-items: center;
      column-gap: 27px;
      row-gap: 50px;
      margin-top: 5rem;
      padding-bottom: 3rem;

      align-items: center;

      div {
        padding: 25px;
        width: 15rem;
        height: 15rem;
        border-radius: 10px;
        background-color: #fbfbfb;

        border: 1px solid #f1f1f1;
      }
    }

    .block-homepage-client {
      cursor: pointer;
      transition: all 300ms;
      &:hover {
        box-shadow: 1.5px 2.5px 15px rgba(184, 184, 184, 0.2);
        transform: scale(1.05);
      }

      .title-block {
        color: $primary400;
        font-weight: 600;
        font-size: 1.3rem !important;
        margin-top: 1rem !important;
      }
      img {
        margin-top: 4px;
        width: 2.3rem;
      }

      p {
        font-size: 0.875rem;
        margin-top: 1.5rem !important;
      }

      .files-icon {
        height: 2.7rem;
      }

      .setting-icon {
        color: $primary400;
        margin-top: 4px;
        width: 2.3rem;
        height: 2.05rem;
      }

      .myteam-icon {
        color: $primary400;
        margin-top: 4px;
        width: 2rem;
        height: 2.05rem;
      }
      .knowledge-title {
        color: $primary400;
        font-weight: 600;
        font-size: 1.3rem !important;
        margin-top: 1rem !important;
      }
    }
  }

  @media (max-width: 1100px) {
    .homepage-menu-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
      column-gap: 27px;
      row-gap: 50px;
      margin-top: 3rem;
      padding-bottom: 3rem;

      align-items: center;

      div {
        padding: 25px;
        width: 15rem;
        height: 15rem;
        border-radius: 10px;
        background-color: #fbfbfb;

        border: 1px solid #f1f1f1;
      }
    }
  }
}
