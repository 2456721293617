.accueil-section {
  font-size: 1.4rem;
  font-family: "Inter", sans-serif;
  width: 100%;
  height: 100vh;

  display: flex;
  p {
    margin-bottom: 3rem;
  }

  .leftdiv {
    width: 60%;

    justify-content: center;
    .houston-logo {
      width: 100%;
      height: 100%;
      position: absolute;
      margin-left: 3rem;
    }
    @media screen and (max-width: 1200px) {
      display: none;
    }
  }

  .log-in-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 40%;
    justify-content: center;
    text-align: center;

    h1 {
      font-size: 1.5rem;
      font-weight: 700;
    }

    h4 {
      color: #000000;
      margin-top: 0.1rem;
      font-size: 0.875rem;
      margin-bottom: 5.25rem;
      font-weight: 500;
    }

    img {
      width: 11.5rem;
      height: 2.75rem;
      animation: apparition 0.4s 1.6s ease-out forwards;
    }
    @media screen and (max-width: 1200px) {
      width: 100%;
    }
  }

  form {
    display: flex;
    flex-direction: column;

    align-items: center;

    .label-email-password {
      display: flex;
      justify-content: left;
      width: 100%;
      color: #171717;
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }

    .button-connexion {
      position: relative;
      background: $primary500;
      border-radius: 2.813rem;
      color: white;
      width: 26.5rem;
      height: 3.438rem;
      margin-top: 1rem;
      font-size: 1rem;
      border: none;
      transition: all 400ms;
      opacity: 0.4;
      cursor: pointer;
      &:hover {
        opacity: 0.9;
      }
    }
  }
  .down-login-div {
    position: absolute;
    bottom: 0.2rem;
    width: 16.8rem;
    font-size: 0.9rem;
    color: #181a42;
  }
  .input-email {
    height: 3.438rem;
    width: 26.5rem;
    color: #000000;
    border: 1px solid #d7d7d7;

    border-radius: 45px;
    outline: none;
    padding: 1.5rem;
  }
  .input-password {
    height: 3.438rem;
    width: 26.5rem;
    color: #000000;
    border: 1px solid #d7d7d7;

    border-radius: 45px;
    outline: none;
    padding: 1.5rem;
  }

  .under-password {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 26.5rem;

    .checkbox-remember {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    input {
      border-radius: 4px;
      width: 0.938rem;
      height: 0.938rem;
      background: #fff;
      border: 1px solid #000000;
      cursor: pointer;
    }

    label {
      font-size: 0.875rem;
      margin-left: 0.2rem;
      font-weight: 300;
    }

    span {
      font-size: 0.875rem;
      cursor: pointer;
      transition: all 300ms;

      &:hover {
        color: $primary400;
      }
    }
  }

  .warning-email {
    display: flex;
    font-size: 0.875rem;
    width: 100%;
    justify-content: left;
    color: #de4341;
    margin-bottom: 3.125rem;
    margin-top: 0.3rem;
  }

  .warning-password {
    display: flex;
    width: 100%;
    justify-content: left;
    font-size: 0.875rem;
    color: #de4341;
    margin-bottom: 1.813rem;
    margin-top: 0.3rem;
  }
}
.error-msg {
  color: #de4341;
}
