.accueil-all-client-page {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  font-family: "Inter", sans-serif;
  padding-bottom: 30vh;

  .container {
    margin-top: 60px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    max-width: auto;
    grid-gap: 24px;
    align-items: center;
    .no-client-div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70vw;
      text-align: center;
      .h1-noclient {
        font-size: 1.6rem;
      }
    }
  }

  .add-client-div {
    border: 1px solid #e5e5e5;
    height: 12.5rem;
    width: 12.5rem;
    background-color: white;
    text-align: center;
    border-radius: 0.625rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    margin-bottom: 2.5rem;
    color: #b5b5b5;
    transition: color 400ms;
    &:hover {
      color: #6935d7;
    }
  }

  .client-div {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      border: 0.5px solid #f9f9fc;
      height: 12.5rem;
      width: 12.5rem;
      background-color: white;
      border-radius: 10px;
      transition: all 300ms;

      &:hover {
        box-shadow: 1.5px 2.5px 25px rgba(184, 184, 184, 0.2);
        transform: scale(1.05);
      }
    }

    h4 {
      margin-top: 1.25rem;
      font-weight: 500;
      letter-spacing: -0.03em;
    }
  }

  @media (max-width: 1500px) {
    .container {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  @media (max-width: 1200px) {
    .container {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media (max-width: 1000px) {
    .add-client-div {
      height: 9.5rem;
      width: 9.5rem;
    }

    .client-div {
      img {
        height: 9.5rem !important;
        width: 9.5rem !important;
      }
    }
  }
  @media (max-width: 800px) {
    .container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
