.client-section-roadmap {
  height: 100%;
  display: flex;
  background: white;
  flex-direction: column;
  align-items: center;

  font-family: "Inter", sans-serif;

  .iframe {
    width: 100%;
    height: 100vh;

    background: url("../../assets/loading.gif") 0px 0px no-repeat;
    background-position: center;
  }
}

.description-roadmap {
  .display-arrow-msg {
    display: flex;
    align-items: center;
    margin-left: 1.2rem;

    span {
      font-size: 0.8rem;
      color: #272727;
    }
  }

  height: 100%;
  width: 100%;
  background-color: white;
  border: 1px solid #d8d8d8;
  margin-bottom: 6rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.description-roadmap-top {
  display: flex;

  flex-direction: column;
  height: 9rem;
  width: 75%;
  background-color: white;
  border: 1px solid #d8d8d8;

  margin-top: 6rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;

  span {
    font-size: 9px;
    margin-left: 2.5rem;
    margin-top: 4rem;
    color: gray;
  }

  h2 {
    font-size: 2.4rem;
    margin-left: 2.4rem;
  }
}
