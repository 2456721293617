.drag-and-drop-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  text-align: center;
  align-items: center;
  margin-top: 4rem;

  .drag-msg {
    display: flex;
    align-items: center;
    font-size: 14px;
  }

  .choose-file {
    color: #6935d7;
    text-decoration: underline;
    margin-left: 3px;
    cursor: pointer;
  }

  .upload-files {
    display: flex;
    font-weight: 500;
    justify-content: flex-start;
    margin-bottom: 0.7rem;
    font-size: 1rem;
    width: 41rem;

    line-height: 19px;
    letter-spacing: -0.03em;

    color: #171717;
  }

  .drag-and-drop {
    height: 6.25rem;
    width: 41rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border: dashed 1px #f1f1f1;
    border-radius: 0.8rem;
    cursor: pointer;
    background: #fefefe;
  }

  .infos-files {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: #505050;
    width: 41rem;
    margin-top: 0.7rem;
  }

  .title-uploaded {
    color: #6935d7;
    display: flex;
    justify-content: flex-start;
    width: 41rem;
    margin-top: 3.75rem;
    font-size: 1rem;
    font-weight: 500;
  }

  .card-files {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    width: 41rem;
  }

  .aside-draganddrop {
    margin-top: 2rem;

    .file-card {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-radius: 10px;
      border: 1px solid #f1f1f1;
      width: 200px;
      height: 140px;
      background-color: white;
      color: #505050;
    }

    .list-files {
      font-size: 12px;
      color: #505050;
    }

    .close-drag {
      position: relative;
      top: 22px;
      z-index: 2;
      background-color: white;
      border: 1px solid #e3e3e3;
      width: 28px;
      height: 28px;
      border-radius: 25px;
      margin-left: 181px;

      &:hover {
        background-color: #f1f1f1;
      }
    }

    .cross {
      margin-top: 5.5px;
      margin-left: 1px;
    }

    .file-icon {
      width: 2rem;
    }

    .file-name {
      margin-top: 1rem;
      font-size: 12px;
    }

    .list-files-modal {
      margin-top: 12px;
    }

    .card-files-modal {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 10px;
      width: 42rem;
      max-height: 15rem;
      overflow-y: auto;
    }
  }
  .rejected-files-title {
    display: flex;
    color: $primary500;
    font-size: 1rem;
    font-weight: 500;
    margin-top: 2rem;
  }

  .rejected-files-draganddrop {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 41rem;
    margin-top: 1rem;

    .rejected-files-description {
      color: $error400;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;

      span {
        display: flex;
        font-size: 0.875rem;
      }
    }
  }
  .btn-upload-files {
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 10px 21px;
    width: 115px;
    height: 38px;
    background: #f7f4fd;
    border-radius: 45px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: -0.03em;
    border: none;
    color: $primary500;

    &:hover {
      background-color: $primary400;
      color: white;
      transition: all 0.5s ease-in-out;
    }
  }
  .close-modal {
    width: 100%;
    display: flex;
    justify-content: right;
  }

  .close-modal-button {
    position: absolute;
    background-color: white;
    border: 1px solid #e3e3e3;
    width: 28px;
    height: 28px;
    border-radius: 25px;
    margin-left: 181px;

    .cross-modal {
      margin-left: 5px;
      margin-top: 5px;
    }

    &:hover {
      background-color: #f1f1f1;
    }
  }
}
