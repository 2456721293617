.balls {
  margin: 10px;
}
#ball {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  border-radius: 50%;
}
#ball1 {
  width: 25px;
  height: 25px;
  background-color: #000000;
  border-radius: 50%;
  animation: ball1 1.7s infinite ease;
}
#ball2 {
  width: 25px;
  height: 25px;
  background-color: #000000;
  border-radius: 50%;
  animation: ball1 1.7s infinite ease 0.1s;
}
#ball3 {
  width: 25px;
  height: 25px;
  background-color: #000000;
  border-radius: 50%;
  animation: ball1 1.7s infinite ease 0.2s;
}
@keyframes ball1 {
  0% {
    margin-top: 0px;
  }
  10% {
    margin-top: -50px;
  }
  20% {
    margin-top: 0px;
  }
  100% {
    margin-top: 0px;
  }
}
@keyframes ball2 {
}
@keyframes ball3 {
}
#credits a {
  color: #8b46e2;
  text-decoration: none;
}
#credits a:hover {
  color: #ffdd00;
}
