.navbar {
  width: 100%;
  background-color: #222222;
  color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 8vh;

  .logo {
    font-size: 1.8rem;
    color: #abb3fa;
    font-weight: bold;
    cursor: pointer;
  }
  a {
    text-decoration: none;
    color: white;
  }

  .nav-links {
    display: flex;
    justify-content: space-around;
    width: 40%;
    flex-direction: row;
    align-items: center;
  }

  .logout {
    border: 1px solid white;
  }
}
