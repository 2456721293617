.glide-section-nav {
  display: flex;

  flex-direction: column;
  align-items: center;

  .glide-navigation {
    display: flex;
    width: 466px;
    justify-content: space-between;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 0px;
    margin-top: 2rem;
    align-items: center;

    a {
      text-decoration: none;
      font-size: 1rem;
      color: #939393;
      padding-bottom: 0px;
    }

    .active {
      border-bottom: 3px solid $primary400;
      color: $primary400 !important;
    }
  }
}
