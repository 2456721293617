.btn-add-more {
  border: 1px solid #e8e8e8;
  background-color: #fefefe;
  width: 6rem;
  height: 2rem;
  border-radius: 2rem;
  cursor: pointer;
  transition: all 400ms;
  &:hover {
    border: 1px solid #fafafa;
    background-color: #e8e8e8;
  }
}
