// Display Headings

.display01--Semi-Bold {
  font-size: 3.75rem;
  font-weight: 600;
}

.display01--Bold {
  font-size: 3.75rem;
  font-weight: 700;
}

.display02--Semi-Bold {
  font-size: 3rem;
  font-weight: 600;
}

.display02--Bold {
  font-size: 3rem;
  font-weight: 700;
}

// Heading 1

.heading01--Regular {
  font-size: 2.25rem;
  font-weight: 400;
}
.heading01--Medium {
  font-size: 2.25rem;
  font-weight: 500;
}

.heading01--Semi-Bold {
  font-size: 2.25rem;
  font-weight: 600;
}
.heading01--Bold {
  font-size: 2.25rem;
  font-weight: 700;
}
.heading01--Extra-Bold {
  font-size: 2.25rem;
  font-weight: 800;
}

// Heading 2

.heading02--Regular {
  font-size: 1.875rem;
  font-weight: 400;
}
.heading02--Medium {
  font-size: 1.875rem;
  font-weight: 500;
}

.heading02--Semi-Bold {
  font-size: 1.875rem;
  font-weight: 600;
}
.heading02--Bold {
  font-size: 1.875rem;
  font-weight: 700;
}
.heading02--Extra-Bold {
  font-size: 1.875rem;
  font-weight: 800;
}

// Heading 3

.heading03--Regular {
  font-size: 1.5rem;
  font-weight: 400;
}
.heading03--Medium {
  font-size: 1.5rem;
  font-weight: 500;
}

.heading03--Semi-Bold {
  font-size: 1.5rem;
  font-weight: 600;
}
.heading03--Bold {
  font-size: 1.5rem;
  font-weight: 700;
}
.heading03--Extra-Bold {
  font-size: 1.5rem;
  font-weight: 800;
}

// Heading 4

.heading04--Regular {
  font-size: 1.125rem;
  font-weight: 400;
}
.heading04--Medium {
  font-size: 1.125rem;
  font-weight: 500;
}

.heading04--Semi-Bold {
  font-size: 1.125rem;
  font-weight: 600;
}
.heading04--Bold {
  font-size: 1.125rem;
  font-weight: 700;
}
.heading04--Extra-Bold {
  font-size: 1.125rem;
  font-weight: 800;
}

// Heading 5

.heading05--Regular {
  font-size: 1rem;
  font-weight: 400;
}
.heading05--Medium {
  font-size: 1rem;
  font-weight: 500;
}

.heading05--Semi-Bold {
  font-size: 1rem;
  font-weight: 600;
}
.heading05--Bold {
  font-size: 1rem;
  font-weight: 700;
}
.heading05--Extra-Bold {
  font-size: 1rem;
  font-weight: 800;
}

// Heading 6

.heading06--Regular {
  font-size: 0.75rem;
  font-weight: 400;
}
.heading06--Medium {
  font-size: 0.75rem;
  font-weight: 500;
}

.heading06--Semi-Bold {
  font-size: 0.75rem;
  font-weight: 600;
}
.heading06--Bold {
  font-size: 0.75rem;
  font-weight: 700;
}
.heading06--Extra-Bold {
  font-size: 0.75rem;
  font-weight: 800;
}

// SubHeading

.subheading--regular {
  font-size: 0.813rem;
  font-weight: 400;
}

.subheading--medium {
  font-size: 0.813rem;
  font-weight: 500;
}

.subheading--underline {
  font-size: 0.813rem;
  font-weight: 400;
  text-decoration-line: underline;
}
