.folder-component {
  width: 100%;
  height: auto;
  margin-top: 12vh;
  h2 {
    margin-bottom: 2rem;
    font-size: 1.25rem;
    letter-spacing: -0.3px;
    font-weight: 600;
  }
  .folder-button-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));

    .category-button {
      padding: 1rem;
      margin: 0.7rem;
      height: 2rem;
      width: 9rem;
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 0.8rem;
      border: none;
      border-radius: 76px;
      background: #e0e0e0;
      box-shadow: 2px 2px 1px #d0d0d0, -2px -2px 1px #f0f0f0;
      color: #b7b7b8;
      background: #f9fafb;
      transition: all 300ms;
      font-weight: 600;
      &:hover {
        cursor: pointer;
        color: #864ae5;
        background: #f4edff;
      }
      &:focus {
        color: #864ae5;
        background: #f4edff;
      }
    }
  }
}
.container-span-folder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
