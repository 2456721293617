.last-step {
  font-family: "Inter", sans-serif;
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  background: $secondary100;
  align-items: center;

  h4 {
    font-size: 20px;
    font-weight: 500;
  }

  .table-title {
    display: flex;
    align-content: center;
    background-color: #f7f4fd;
    align-items: center;
    height: 3rem;
    border: 0.5px solid #e9e9e9;
    border-radius: 10px 10px 0px 0px;
    margin-top: 2rem;
    width: 100%;
    border-bottom: 0.5px solid #e9e9e9;
  }
  .button-submit {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }

  .four {
    color: $primary500;
    text-align: center;
    margin-top: 3.75rem;
    font-size: 0.75rem;
  }
  .title-step4 {
    text-align: center;
    font-size: 2.25rem;
    margin-bottom: 5rem;
  }

  .container-last-div {
    display: flex;
    flex-direction: column;

    width: 52.188rem;
  }

  .team-edit {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
  }

  .first-title {
    display: flex;
    align-content: center;
    height: 2rem;
    margin-top: 2rem;
    width: 100%;
    border-bottom: 1px solid #000000;
  }

  .infos-client {
    display: flex;
  }

  .name-info {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-left: 2rem;
    margin-top: 0.4rem;

    h6 {
      margin-bottom: 12px;
      font-size: 1rem;
    }

    p {
      color: #4f4f4f;
      font-size: 0.813rem;
    }
  }

  .email-info {
    margin-top: 0.4rem;
    h6 {
      margin-bottom: 12px;
      font-size: 1rem;
    }

    p {
      color: #4f4f4f;
      font-size: 0.813rem;
    }
  }

  .password-info {
    display: flex;
    flex-direction: column;
    background: #f7f4fd;
    padding: 16px 16px 25px 12px;
    border: 0.1px solid #f0f0f0;
    border-radius: 3px;
    border-top: none;

    h6 {
      margin-left: 2rem;
    }

    p {
      color: #4f4f4f;
      font-size: 0.813rem;
      margin-top: 0.7rem;
      margin-left: 2rem;
    }
  }

  .title-roles {
    width: 50%;
    font-size: 0.813rem;
  }

  .title-name {
    width: 40%;
    font-size: 0.813rem;
    margin-left: 2rem;
  }

  .name-role {
    display: flex;
    flex-direction: row;
    height: 3.5rem;
    align-items: center;
    color: #4f4f4f;
    width: 100%;
    font-size: 0.813rem;
    border: 0.5px solid #e9e9e9;
    border-top: none;
    background: white;
  }

  .name {
    margin-left: 2rem;
    width: 40%;
    font-size: 0.813rem;

    letter-spacing: -0.03em;
  }
  .role {
    width: 50%;
    font-size: 0.813rem;

    letter-spacing: -0.03em;
  }

  .title-edit {
    margin-top: 5rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 3.25rem;
    font-size: 1.25rem;
    align-items: center;
    margin-top: 80px;
  }

  .infos-client {
    width: 100%;
    height: 5.313rem;
    background: #f7f4fd;
    padding: 16px 16px 16px 12px;
    border: 0.1px solid #f0f0f0;
    border-radius: 3px;
    border-bottom: none;
  }

  .table-title-step5 {
    display: flex;
    align-content: center;
    background-color: #f7f4fd;
    align-items: center;
    height: 3rem;
    border: 0.5px solid #e9e9e9;
    border-radius: 10px 10px 0px 0px;
    margin-top: 2rem;
    width: 100%;
    border-bottom: 0.5px solid #e9e9e9;

    width: 100%;
  }

  .title-file {
    width: 60%;
    font-size: 0.813rem;
    margin-left: 2rem;
  }

  .title-type {
    width: 20%;
    font-size: 0.813rem;
  }

  .title-size {
    width: 20%;
    font-size: 0.813rem;
  }

  .fileName {
    width: 60%;
    font-size: 0.813rem;
    margin-left: 2rem;
  }
  .type {
    width: 20%;
    font-size: 0.813rem;
  }

  .file-extension-laststep {
    width: 2rem;
  }

  .size {
    width: 20%;
    font-size: 0.813rem;
  }

  .file-settings {
    height: 3.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #4f4f4f;
    width: 100%;
    border: 0.5px solid #e9e9e9;
    border-top: none;
    background: white;
  }

  .title-datastudio {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .datastudio-links {
    background: #f7f4fd;
    margin-bottom: 1.5rem;
    border: 0.1px solid #f0f0f0;
    border-radius: 3px;
    a {
      text-decoration: none;
      color: #616161;
      font-size: 13px;
      &:hover {
        color: $primary400;
      }
    }
    padding: 16px 16px 16px 12px;
  }

  .roadmap-display {
    background: #f7f4fd;

    border: 0.1px solid #f0f0f0;
    border-radius: 3px;
    a {
      text-decoration: none;
      color: #616161;
      font-size: 13px;
      &:hover {
        color: $primary400;
      }
    }
    padding: 16px 16px 16px 12px;
    margin-bottom: 30px;
  }

  .btn-edit {
    border: 1px solid #e8e8e8;
    background-color: #fefefe;
    width: 4.125rem;
    height: 2.313rem;
    font-size: 14px;
    border-radius: 1.688rem;
    cursor: pointer;
    transition: all 400ms;
    &:hover {
      border: 1px solid #fafafa;
      background-color: #cfcaca;
    }
  }

  .btn-validate {
    font-family: "Inter", sans-serif;
    font-size: 1rem;
    background-color: #6935d7;
    color: white;
    width: 14.188rem;
    height: 3.688rem;
    border-radius: 45px;
    border: none;
    margin-top: 5rem;
    transition: all 700ms;
    cursor: pointer;
    cursor: pointer;
  }

  .alert-danger {
    color: #721c24;
    background: #f8d7da;
  }
  .alert-success {
    color: #155724;
    background: #d4edda;
  }

  .laststep-nodata-msg {
    font-size: 0.875rem;
    display: flex;
    margin-top: 1rem;
    margin-left: 1.5rem;
  }
}
