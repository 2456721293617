.section-contact {
  min-height: 100vh;
  display: flex;
  padding-bottom: 30vh;
  width: 52.188rem;
  font-family: "Inter", sans-serif;
  background: white;
  flex-direction: column;
  align-content: center;
  .title-team-members {
    color: #8b8b8b;
    font-size: 14px;
    font-weight: 400;
    margin-top: 48px;
    margin-bottom: 28px;
  }

  .title-add-contact {
    color: #8b8b8b;
    font-size: 0.875rem;
    font-weight: 400;
    margin-top: 5rem;
    margin-bottom: 3.125rem;
  }

  .table-title {
    display: flex;
    align-content: center;
    background-color: #f7f4fd;
    align-items: center;
    height: 3rem;
    border: 0.5px solid #e9e9e9;
    border-radius: 10px 10px 0px 0px;
    margin-top: 2rem;
    width: 100%;
    border-bottom: 0.5px solid #e9e9e9;

    .title-name {
      margin-left: 2rem;
    }

    .title-roles {
      // margin-right: 8rem; a revoir
      margin-left: 28.5%;
    }
  }

  label {
    display: flex;
    font-weight: 500;
    font-size: 1rem;
  }

  .input-first-step {
    width: 19.5rem;
    height: 3.563rem;
    border: none;
    border: 0.5px solid #e8e8e8;
    padding: 1.25rem;
    border-radius: 10px;
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
    margin-right: 2rem;
  }

  ::placeholder {
    color: gray;
    font-size: 0.875rem;
  }

  .name-role-settings {
    display: flex;
    flex-direction: row;
    align-items: center;

    color: #4f4f4f;
    width: 100%;
    font-size: 0.813rem;
    border-bottom: 0.5px solid #606060;
    justify-content: space-between;
    height: 3.5rem;
    border: 0.5px solid #e9e9e9;
    border-top: none;

    .name-settings {
      width: 30%;
      font-size: 0.813rem;
      margin-left: 2rem;
      letter-spacing: -0.03em;
    }
    .role-settings {
      width: 48%;
      font-size: 0.813rem;

      letter-spacing: -0.03em;
    }

    .delete-consultant-btn {
      margin-right: 2rem;
    }
  }

  .container-add-contact {
    display: flex;

    justify-content: space-between;
  }

  .div-password-button {
    display: flex;
  }

  .add-member {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 21px;
    width: 115px;
    height: 38px;
    background: #f7f4fd;
    border-radius: 45px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: -0.03em;
    border: none;
    color: $primary500;
    margin-top: 40px;

    &:hover {
      background-color: $primary400;
      color: white;
      transition: all 0.5s ease-in-out;
    }
  }
}
