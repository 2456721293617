// Primary colors

$primary200: #cfc1ed;
$primary400: #9145db;
$primary500: #6935d7;
$primary700: #5415d7;

// Secondary colors

$secondary100: #fafafa;
$secondary400: #e0e0e0;
$secondary400: #b2b2b2;

// Success colors

$success200: #82e5aa;
$success400: #47b780;
$success600: #2a7654;

// Warning colors

$warning200: #f7db5c;
$warning400: #eba630;
$warning600: #aa5419;

// Error colors

$error200: #f3a6a5;
$error400: #de4341;
$error600: #ac2519;

// Shades colors :

$shades01: #000000;
$shades02: #ffffff;
