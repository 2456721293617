:root {
  /* ===== Colors ===== */
  --body-color: #e4e9f7;
  --sidebar-color: #191919;
  --primary-color: #e3baa2;
  --primary-color-light: #f6f5ff;
  --toggle-color: #ddd;
  --text-color: #ffffff;

  /* ====== Transition ====== */
  --tran-03: all 0.2s ease;
  --tran-03: all 0.3s ease;
  --tran-04: all 0.3s ease;
  --tran-05: all 0.3s ease;
}
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  padding: 10px 14px;
  background: var(--sidebar-color);
  border: 1px solid black;
  z-index: 100;
  transition: var(--tran-05);
  .menu {
    margin-top: 35px;
  }
}
.sidebar.close {
  width: 88px;
  .text {
    opacity: 0;
  }
  header {
    .toggle {
      transform: translateY(-50%);
    }
  }
  .menu-title {
    opacity: 0;
  }

  .text-disable {
    display: none;
  }
}
.sidebar .image-text img {
  width: 40px;
  border-radius: 6px;
}

.sidebar {
  header {
    .image-text {
      display: flex;
      align-items: center;
    }
  }
}

.sidebar {
  .text {
    font-size: 14px;
    font-weight: 400;
    color: var(--text-color);
    transition: var(--tran-04);
    white-space: nowrap;
    opacity: 1;
  }
}

.sidebar {
  .image {
    min-width: 60px;
    display: flex;
    align-items: center;
  }
}

header {
  .image-text {
    .header-text {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }
}

.header-text {
  .name {
    font-weight: 600;
    margin-top: -2px;
  }
  .greeting {
    font-weight: 600;
  }
}

.sidebar {
  header {
    .toggle {
      position: absolute;
      top: 50%;
      right: -25px;
      transform: translateY(-50%) rotate(180deg);
      height: 25px;
      width: 25px;
      background: var(--primary-color);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      color: var(--sidebar-color);
      font-size: 22px;
    }
  }
}

.sidebar {
  header {
    position: relative;
  }
}
.sidebar {
  li {
    height: 50px;
    margin-top: 10px;
    list-style: none;
    display: flex;
    align-items: center;
    color: var(--text-color);
    .icon {
      height: 25px;
      color: var(--text-color);
      min-width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: var(--tran-03);
    }
    .text {
      transition: var(--tran-03);
    }

    a {
      text-decoration: none;
      border-radius: 6px;
      height: 100%;
      display: flex;
      align-items: center;
      transition: var(--tran-04);
      width: 100%;
      &:hover {
        background: var(--primary-color);
      }
    }
  }
}

.sidebar li a:hover .icon,
.sidebar li a:hover .text {
  color: var(--sidebar-color);
}

.sidebar {
  .menu-bar {
    height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.nav-link-disable {
  .icon-disable {
    color: gray;
    height: 25px;

    min-width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .text-disable {
    color: gray;
    font-size: 14px;
  }
}

.menu-title {
  color: var(--primary-color);
  margin-top: 10px;
  display: block;
}

@media (max-width: 1000px) {
  .sidebar {
    display: none;
  }
  .sidebar-close {
    display: none;
  }
}

.aside-navsidebar {
  background-color: #fbfbfb;
  //height: 100vh;
  height: 100%;
  padding-top: 20px;
  padding-left: 43px;
  padding-bottom: 20px;
  border-right: 1px solid #eeeeee;
  width: 15rem;
  left: 0;
  display: flex;
  flex-direction: column;
  transition: all 500ms;
  flex-grow: 0;
  flex-shrink: 0;
  //z-index: 2234;

  .active {
    background-image: url("../../assets/chevron.svg");
    background-repeat: no-repeat;
    background-position: right;

    // height: 30px;

    filter: brightness(0) saturate(100%) invert(34%) sepia(85%) saturate(3265%)
      hue-rotate(253deg) brightness(89%) contrast(93%);
  }

  .arrow {
    display: flex;
    justify-content: flex-end;
    padding-right: 15px;
  }
  .sidebar-display-user {
    display: flex;
    justify-content: center;
    img {
      cursor: pointer;
    }
  }
  .figure-img-admin {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 43px;

    span {
      font-size: 0.875rem;
      color: #aeb0b6;
      letter-spacing: -0.05em;
      display: flex;
      justify-content: center;
      margin-top: 0.3rem;
      align-items: center;
    }
    img {
      width: 6rem;
      height: 6rem;
      border-radius: 5%;
      object-fit: cover;
      cursor: pointer;
      filter: drop-shadow(0 0 0.5px rgba(0, 0, 0, 0.15));
    }

    .hand-svg {
      width: 1.3rem;
      height: 1.3rem;
    }

    figcaption {
      font-size: 1rem;
      font-weight: 500;
      letter-spacing: -0.05em;
      display: flex;
      justify-content: center;
    }
  }

  // .links-img-sidebar {
  //   display: flex;
  //   align-items: center;
  // }

  .sidebar-display-cockpit-client {
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    .div-figcaption-cockpit {
      flex-direction: column;
      margin-left: 6px;
      letter-spacing: -0.05em;

      .fig-client-name {
        font-size: 15px;

        font-weight: 600;
      }

      .fig-cockpit {
        font-size: 13px;
        font-weight: 500;
        color: #aeb0b6;
        margin-top: 2px;
      }
    }
  }

  .figure-img-client {
    width: 100%;

    padding-bottom: 30px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    padding-right: 43px;
    img {
      width: 3rem;
      height: 3rem;
      border-radius: 0.5rem;
      border: 0.5px solid #e6e6e6;
      cursor: pointer;
    }
  }

  .nav-sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .div-content-consultant {
      display: flex;
      flex-direction: column;
      margin-top: 1.5rem;
      letter-spacing: -0.05em;
      h1 {
        color: #aeb0b6;
        font-size: 12px;
      }

      .div-link-consultant {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        letter-spacing: -0.05em;
        font-size: 0.875rem;
        // border: 1px solid green;
        // height: 20rem;
        /*         div {
          border: 1px solid red;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        } */
        div {
          /*  h1 {
          color: #aeb0b6;
          font-size: 12px;
        } */
          a {
            span {
              margin-left: 0.7rem;
            }
          }
        }
      }
    }

    .div-content-client {
      display: flex;
      flex-direction: column;

      h1 {
        color: #aeb0b6;
        font-size: 12px;
      }

      .div-link-client {
        display: flex;
        flex-direction: column;

        font-size: 0.875rem;

        img {
          color: #797d91;
        }
      }
    }
  }

  footer {
    display: block;
    flex: 0 0 auto;
    margin-top: auto;

    .link-to-ynsite {
      flex-direction: column;
      align-items: baseline;
      margin-top: 0px;
    }

    h1 {
      font-size: 0.875rem;
      color: $primary400;
    }

    .dev-by-yn-msg {
      font-size: 10px;
      color: #797d91;
    }

    .logout-div {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-bottom: 10.5px;
      color: #797d91;
      font-size: 14px;
      font-weight: 500;

      span {
        line-height: 17px;
        letter-spacing: -0.03em;
      }

      &:hover {
        color: $primary400;
        transition: all 300ms;
      }

      .log-out-btn {
        cursor: pointer;
      }
    }
  }

  .link-menu-sidebar {
    &:hover {
      filter: brightness(0) saturate(100%) invert(34%) sepia(85%)
        saturate(3265%) hue-rotate(253deg) brightness(89%) contrast(93%);
      // background: rgba(247, 246, 249, 0.01);
    }
  }

  a {
    text-decoration: none;
    color: #797d91;

    margin-top: 27px;
    display: flex;
    align-items: center;

    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    letter-spacing: -0.03em;

    &:hover {
      // color: $primary500;
      //  background-color: #f7f6f9;
      // height: 45px;
      // background-color: #f7f6f9;
      // border-radius: 5px;
    }
  }

  @media (max-height: 850px) {
    .figure-img-admin {
      margin: 0 !important;
    }
    .figure-img-client {
      padding-bottom: 15px;
    }

    a {
      margin-top: 20px;
    }
  }

  @media (max-height: 750px) {
    .figure-img-client {
      padding-bottom: 15px;
    }

    a {
      margin-top: 14px;
    }
  }

  @media (max-height: 700px) {
    .nav-sidebar {
      overflow: scroll;
    }
    .figure-img-client {
      display: none !important;
    }
  }

  @media (min-width: 1500px) {
    width: 16rem;

    .figure-img-admin {
      margin-bottom: 3.125rem;
      margin-top: 3.125rem;
    }

    a {
      font-size: 16px;
    }
  }
}

// ___________ SIDEBAR CLOSED ___________ //

.aside-close {
  background-color: #f9f9fc;
  height: 100vh;
  padding: 20px;
  width: 7rem;
  display: flex;
  flex-direction: column;
  transition: all 500ms;

  left: 0;
  z-index: 2234;

  .client-here {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    margin-bottom: 1rem;
    color: #797d91;
    &:hover {
      color: $primary500;
    }
    span {
      margin-left: 0.7rem;
      font-size: 14px;
    }

    .log-out-btn {
      cursor: pointer;
      transition: all 300ms;

      &:hover {
        color: $primary500;
      }
    }
  }

  .img-little {
    width: 3rem;
    height: 3rem;
    border-radius: 2rem;
    object-fit: cover;
  }
  .display-none {
    display: none;
  }

  .client-menu {
    margin-top: 2rem;

    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    h1 {
      color: #aeb0b6;
      font-size: 12px;
    }
    a {
      span {
        margin-left: 0.7rem;
      }
    }
  }

  .arrow {
    display: flex;
    justify-content: flex-end;
  }
  .sidebar-display-user {
    display: flex;
    justify-content: center;
  }
  .figure-img-admin {
    width: 100%;
    padding-top: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 3rem;
      height: 3rem;
      border-radius: 10%;
      object-fit: cover;
      cursor: pointer;
      filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.25));
    }

    figcaption {
      display: none;
      font-size: 1rem;
      font-weight: 500;
      margin-top: 10px;
    }
  }

  // .links-img-sidebar {
  //   display: flex;
  //   align-items: center;
  // }

  .sidebar-display-cockpit-client {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }

  .figure-img-client {
    width: 100%;

    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 3rem;
      height: 3rem;
      border-radius: 0.5rem;
      border: 0.5px solid #e6e6e6;
    }

    figcaption {
      display: none;
    }
  }
  .nav-sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .div-content-consultant {
      display: flex;
      flex-direction: column;

      letter-spacing: -0.05em;
      h1 {
        display: none;
      }

      .div-link-consultant {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        letter-spacing: -0.05em;
        font-size: 14px;
        div {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          height: 13rem;
        }

        img {
          width: 3rem;
        }
      }
    }

    .div-content-client {
      display: flex;
      flex-direction: column;
      align-items: center;

      h1 {
        display: none;
      }

      .div-link-client {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        align-items: center;

        font-size: 14px;

        img {
          color: #797d91;
        }
      }
    }

    .div-content-menu {
      display: flex;
      flex-direction: column;
      margin-top: 2rem;
      h1 {
        color: #aeb0b6;
        font-size: 12px;
      }

      .div-link-client {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        align-items: center;

        font-size: 14px;

        img {
          color: #797d91;
        }
      }
    }
  }

  footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;

    h1 {
      transition: all 300ms;
      font-size: 11px;
      color: $primary400;
    }

    span {
      display: none;
    }
  }

  a {
    text-decoration: none;
    color: #797d91;
    margin-top: 18px;
    display: flex;
    align-items: center;
    letter-spacing: -0.05em;
    font-size: 14px;
    &:hover {
      filter: brightness(0) saturate(100%) invert(34%) sepia(85%)
        saturate(3265%) hue-rotate(253deg) brightness(89%) contrast(93%);
    }
  }

  .active {
    filter: brightness(0) saturate(100%) invert(34%) sepia(85%) saturate(3265%)
      hue-rotate(253deg) brightness(89%) contrast(93%);
    background: rgba(247, 246, 249, 0.1);
    padding: 0.8rem;
    border-radius: 0.5rem;
  }

  @media (min-width: 1500px) {
    a {
      margin-top: 40px;
    }
  }
}

//partie quand la nav se referme

.arrow-sidebar {
  color: black;
  cursor: pointer;
  transition: all 400ms;
  &:hover {
    color: $primary500;
  }
}

.client-menu {
  margin-top: 2rem;

  h1 {
    color: #aeb0b6;
    font-size: 12px;
  }
  a {
    span {
      margin-left: 0.7rem;
      font-size: 0.875rem;
    }
  }
}

@media (min-width: 1500px) {
  .logout-div {
    span {
      font-size: 16px;
    }
  }
}
