.client-page-section {
  background-color: white;
  align-items: center;

  font-family: "Inter", sans-serif;
  display: flex;
  flex-direction: column;

  .manage-client-div {
    height: 100%;
  }
  .title-manage-client {
    margin-top: 12vh;
    font-size: 2.25rem;
    font-weight: 600;
    letter-spacing: -0.3px;
    margin-bottom: 3.125rem;
  }
  .client-page-navbar {
    height: 6vh;

    .active {
      border-bottom: 3px solid $primary400;
      color: $primary400 !important;
    }
    .img-div-logo {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding-right: 2rem;
      height: 100%;
      margin-left: 2rem;
      border-right: 1px solid #f1f1f1;
      .img-logo {
        width: 2.5rem;
        height: 2.5rem;
      }
    }

    .link-div {
      display: flex;
      max-width: 600px;
      justify-content: space-between;
      border-bottom: 1px solid #f0f0f0;
      padding-bottom: 0px;

      .active {
        padding-bottom: 12px;
      }

      a {
        text-decoration: none;
        font-size: 1rem;
        color: #939393;
        padding-bottom: 0px;
      }
    }

    .img-div {
      display: flex;
      align-items: center;
      width: 20%;
      height: 100%;
      padding-left: 2rem;
      border-left: 1px solid #f1f1f1;
      img {
        width: 2rem;
        height: 2rem;
        margin-right: 0.5rem;
        border-radius: 0.5rem;
      }
    }
  }
}

.beamer_defaultBeamerSelector {
  position: fixed;
  top: 20px;
  left: 20px;
  width: 30px !important;
  height: 30px !important;
  background-color: #fbfbfb !important;
  background-image: url("../../assets/alert.svg") !important;
  box-shadow: none !important;
}

.header.dark {
  background: url("../../assets/background-popup.svg") !important;
}

.modeTab.widgetMode {
  display: none !important;
}
