.first-step {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  background: $secondary100;
  font-family: "Inter", sans-serif;

  .title-step1 {
    font-weight: 500;
    font-size: 2.25rem;
  }

  .one {
    font-size: 0.75rem;
    color: #6935d7;
    margin-top: 3.75rem;
  }

  .first-column {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;

    margin-top: 5rem;

    .input-name {
      margin-left: 5rem;
    }

    .input-email {
      margin-left: 5rem;
    }

    label {
      display: flex;
      font-weight: 500;
      font-size: 1rem;
    }

    .input-first-step {
      width: 19.5rem;
      height: 3.563rem;
      border: none;
    }
    form {
      display: flex;
    }

    ::placeholder {
      color: gray;
      font-size: 0.875rem;
    }

    input {
      border: none;

      margin-top: 0.6rem;
      margin-bottom: 0.6rem;
      height: 2rem;
      width: 18rem;
      color: gray;

      padding: 1.25rem;
    }
  }

  .title-add-contact {
    display: flex;
    font-size: 1.1rem;
    font-weight: 500;
    margin-left: 5rem;
    margin-bottom: 1.5rem;
  }

  .btn-delete {
    border: none;
    background: none;
    margin-top: 25px;
    cursor: pointer;
  }

  .border-red {
    border: 2px dashed red;
  }

  .btn-actived {
    background-color: #6935d7 !important;
  }

  .error-msg {
    color: $error400;
    font-size: 14px;
    display: flex;
  }

  .title-password-tooltip {
    width: 19.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .password-input-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .section-add-contact {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 1176px;

    ::placeholder {
      color: gray;
      font-size: 0.875rem;
    }

    label {
      display: flex;
      font-weight: 500;
      font-size: 1rem;
    }

    .input-first-step {
      width: 19.5rem;
      height: 3.563rem;
      border: none;
    }

    .input-email {
      margin-left: 5rem;
    }

    .iframe-style {
      display: flex;
      justify-content: flex-start;
      margin-top: 2rem;
    }
  }
}

.generate-password-button {
  border: 1px solid #e8e8e8;
  background-color: #fefefe;
  width: 7rem;
  height: 2.5rem;
  border-radius: 2rem;
  cursor: pointer;
  transition: all 400ms;
  font-size: 0.8rem;
  &:hover {
    border: 1px solid #fafafa;
    background-color: #e8e8e8;
  }
}
