.nav-stepper {
  font-family: "Inter", sans-serif;
  height: 100px;
  display: flex;
  flex-direction: row;
  width: 100%;

  .img-logo {
    display: flex;
    border-right: 1px solid #f1f1f1;
    justify-content: space-evenly;
    align-items: center;
    width: 470px;
    border-right: 1px solid #f1f1f1;
    .logo {
      width: 30px;
    }
    .text-div {
      width: 256px;

      p {
        font-size: 0.75rem;
        color: #505050;
      }
    }
  }

  .step-div-btn-container {
    display: flex;
    align-items: center;
    width: 70%;

    .step-div {
      width: 75%;
      display: flex;
      height: 100%;
      justify-content: center;

      .step-div-liste {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 493px;
        font-size: 1rem;
      }
    }
  }
  .btn-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    width: 5%;
    margin-right: 3rem;
  }
  .close-btn {
    height: 100%;
    border-left: 1px solid #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%;

    img {
      cursor: pointer;
    }
  }
}
