.basic-info-section {
  min-height: auto;
  display: flex;

  width: 52.188rem;
  font-family: "Inter", sans-serif;
  background: white;
  flex-direction: column;
  align-content: center;

  .change-logo-settings {
    color: #8b8b8b;
    font-size: 0.875rem;
    font-weight: 400;
    margin-top: 3rem;
    margin-bottom: 1.75rem;
  }

  .client-logo {
    width: 12.5rem;
    height: 12.5rem;
    border-radius: 10px;
  }
  .custom-file-input::-webkit-file-upload-button {
    width: 0.2rem;
    visibility: hidden;
  }
  .custom-file-input::before {
    content: "Upload Logo";
    font-size: 1.125rem;
    font-family: "Inter", sans-serif;
  }

  .custom-file-input {
    color: #676767;
    position: relative;
    width: 25rem;
    height: 2rem;

    border-radius: 3px;
    padding: 5px 8px;

    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 600;
    font-size: 10pt;

    font-family: Inter-regular;
  }

  .client-logo-container {
    display: flex;

    flex-direction: row;
  }

  .button-description-container {
    margin-left: 1.188rem;
    width: 100%;

    .description-logo-settings {
      color: #7b7b7b;
      font-size: 0.75rem;

      width: 100%;
    }
  }

  .title-change-info-settings {
    color: #8b8b8b;
    font-size: 0.875rem;
    font-weight: 400;
    margin-top: 5rem;
    margin-bottom: 3.125rem;
  }

  .upload-logo-and-input {
    display: flex;
    align-items: center;
  }

  .button-update-settings {
    display: flex;
    justify-content: space-between;
    margin-top: 7rem;

    align-items: flex-end;
  }
  .input-name-email-settings {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    .input-align-button-update {
      display: flex;
      align-items: center;
    }
    .input-name-settings {
      margin-right: 0.5rem;
    }
    input {
      border: 0.5px solid #e8e8e8;

      margin-top: 0.6rem;
      margin-bottom: 0.6rem;
      width: 19.5rem;
      height: 3.563rem;
      color: gray;
      border-radius: 10px;
      padding: 1.25rem;
    }
  }

  .icon-manage-client-update {
    cursor: pointer;
    margin-left: 25px;
  }
  .icon-manage-client-confirm {
    cursor: pointer;
  }

  .btn-upload-logo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 18px;
    width: 115px;
    height: 38px;
    background: #f7f4fd;
    border-radius: 45px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: -0.03em;
    border: none;
    color: $primary500;

    &:hover {
      background-color: $primary400;
      color: white;
      transition: all 0.5s ease-in-out;
    }

    &:active {
      color: black;
    }
  }

  .close-modal-button {
    position: absolute;
    background-color: white;
    border: 1px solid #e3e3e3;
    width: 28px;
    height: 28px;
    border-radius: 25px;
    margin-left: 181px;

    .cross-modal {
      margin-left: 5px;
      margin-top: 5px;
    }

    &:hover {
      background-color: #f1f1f1;
    }
  }

  .header-modal-delete-entity {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 1rem;
    height: 88px;
    width: 100%;

    h3 {
      color: $error400;
      width: 100%;
    }
  }

  .confirm-delete-entity-msg {
    margin-top: 3rem;
    font-weight: 500;
  }

  .input-modal-delete-entity {
    width: 19.5rem;
    height: 3.563rem;
    padding: 1.25rem;
    color: gray;
    margin-top: 2rem;
  }
  .input-delete-btn-modal-div {
    display: flex;
    flex-direction: column;

    .div-last-delete-btn {
      display: flex;
      justify-content: center;
      margin-top: 3rem;
    }
    .last-delete-btn-entity {
      width: 478px;
      color: white;
      background: $error400;
      cursor: pointer;
      border-radius: 25px;
      font-size: 1rem;
      height: 46px;
      border: none;
    }
  }
}
