.manage-consultants {
  background-color: white;
  align-items: center;
  font-family: "Inter", sans-serif;
  display: flex;
  flex-direction: column;

  .title-manage-consultant {
    margin-top: 12vh;
    font-size: 2.25rem;
    font-weight: 600;
    letter-spacing: -0.3px;
    margin-bottom: 3.125rem;
  }
  .content-manage-consultant {
    display: flex;
    height: 100%;
    width: 52.188rem;
    font-family: "Inter", sans-serif;
    background: white;
    flex-direction: column;
    align-content: center;
  }

  .change-logo-settings {
    color: #8b8b8b;
    font-size: 0.875rem;
    font-weight: 400;
    margin-top: 3rem;
    margin-bottom: 1.75rem;
  }

  .client-logo {
    width: 7.5rem;
    height: 7.5rem;
    border-radius: 10px;
  }
  .custom-file-input::-webkit-file-upload-button {
    width: 0.2rem;
    visibility: hidden;
  }
  .custom-file-input::before {
    content: "Upload Photo";
    font-size: 1.125rem;
  }

  .custom-file-input {
    color: #676767;
    position: relative;
    width: 25rem;
    height: 2rem;

    border-radius: 3px;
    padding: 5px 8px;

    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 600;
    font-size: 10pt;

    font-family: Inter-regular;
  }

  .client-logo-container {
    display: flex;
    margin-top: 3rem;
    margin-bottom: 3rem;
    flex-direction: row;
  }

  .button-description-container {
    margin-left: 1.188rem;
    width: 100%;

    .description-logo-settings {
      color: #7b7b7b;
      font-size: 0.75rem;
      margin-left: 7px;
      width: 100%;
    }
  }

  .title-change-info-settings {
    color: #8b8b8b;
    font-size: 1rem;
    font-weight: 400;

    margin-bottom: 3.125rem;
  }

  .upload-logo-and-input {
    display: flex;
    align-items: center;
  }

  .button-update-settings {
    display: flex;
    justify-content: space-between;
    margin-top: 2.5rem;

    align-items: flex-end;
  }
  .input-name-email-settings {
    display: flex;
    align-items: center;
    margin-top: 1rem;

    .input-name-label {
      display: flex;
      flex-direction: column;
    }

    .input-label-email {
      display: flex;
      flex-direction: column;
      margin-left: 5rem;
    }
    input {
      border: 0.5px solid #e8e8e8;

      margin-top: 0.6rem;
      margin-bottom: 0.6rem;
      width: 19.5rem;
      height: 3.563rem;
      color: gray;
      border-radius: 10px;
      padding: 1.25rem;
    }
  }

  .input-password-job-settings {
    display: flex;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 2.2rem;
    input {
      border: 0.5px solid #e8e8e8;

      margin-top: 0.6rem;

      width: 19.5rem;
      height: 3.563rem;
      color: gray;
      border-radius: 10px;
      padding: 1.25rem;
    }

    .password-label-input {
      display: flex;
      flex-direction: column;
    }

    .job-label-input {
      display: flex;
      flex-direction: column;
      margin-left: 5rem;

      .label-job {
        margin-bottom: 0.6rem;
      }
    }
  }

  .icon-manage-client-update {
    cursor: pointer;
    margin-left: 1.563rem;
  }
  .icon-manage-client-confirm {
    cursor: pointer;
  }

  .consultant-list-div {
    width: 52.188rem;
    margin-bottom: 3.125rem;
  }

  .table-title {
    display: flex;
    align-content: center;
    background-color: #f7f4fd;
    align-items: center;
    height: 3rem;
    border: 0.5px solid #e9e9e9;
    border-radius: 10px 10px 0px 0px;
    margin-top: 2rem;
    width: 100%;
    border-bottom: 0.5px solid #e9e9e9;

    .photo-consultant {
      margin-left: 2rem;
      font-size: 0.813rem;
      width: 10%;
    }

    .name-consultant {
      font-size: 0.813rem;
      width: 40%;
    }

    .job-consultant {
      font-size: 0.813rem;
      width: 40%;
    }

    .email-consultant {
      font-size: 0.813rem;
      width: 52%;
    }
  }

  .consultant-display {
    display: flex;
    justify-content: space-between;
    height: 3.5rem;
    border: 0.5px solid #e9e9e9;
    border-top: none;

    align-items: center;

    .consultant-photo-info {
      width: 5%;
      margin-left: 2rem;
      .consultant-img {
        width: 40px;
        height: 40px;
        border-radius: 30px;
      }
    }
    .consultant-name-info {
      width: 25%;
      font-size: 0.813rem;
    }

    .consultant-job-info {
      width: 25%;
      font-size: 0.813rem;
    }

    .consultant-email-info {
      font-size: 0.813rem;
      width: 27%;
    }

    p {
      color: #4f4f4f;
      font-size: 0.813rem;
    }

    .delete-consultant-div {
      margin-right: 2rem;
    }

    .delete-btn {
      cursor: pointer;
      transition: all 300ms;
      &:hover {
        color: $primary500;
        transition: all 0.5s ease-in-out;
      }
    }
  }

  .btn-upload {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    width: 115px;
    height: 38px;
    background: #f7f4fd;
    border-radius: 45px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: -0.03em;
    border: none;
    color: $primary500;

    &:hover {
      background-color: $primary400;
      color: white;
      transition: all 0.5s ease-in-out;
      cursor: pointer;
    }
  }

  .clients-label-input {
    display: flex;
    margin-top: 1.5rem;
  }

  .cross-delete-clients {
    margin: 15px;
    border: none;
    cursor: pointer;
    background: none;
    display: flex;
  }

  .div-btn-add-clients {
    display: flex;
    justify-content: center;
    width: 708px;
    .btn-add-clients {
      margin-top: 1rem;
      border: none;
      cursor: pointer;
      background: none;
    }
  }

  .select-role-div {
    margin-left: 5rem;
  }

  form {
    display: flex;
    flex-direction: column;
  }
}
