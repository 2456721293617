.container-news {
  background-color: white;
  align-items: center;
  font-family: "Inter", sans-serif;
  display: flex;
  flex-direction: column;

  .content-news {
    min-height: 100vh;
    display: flex;

    font-family: "Inter", sans-serif;
    background: white;
    flex-direction: column;
    align-content: center;
    width: 44.25rem;
  }

  .article-props {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.05em;

    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 1rem;
      h3 {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.05em;

        color: #000000;

        &:hover {
          color: $primary500;
        }
      }
    }
  }

  .container-post {
    display: flex;
    flex-direction: column;

    a {
      text-decoration: none;
      color: black;
    }
  }

  .allhands-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 21px;
    width: 115px;
    height: 38px;
    background: #f7f4fd;
    border-radius: 45px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 13px;
    letter-spacing: -0.03em;

    color: $primary500;
    margin-bottom: 1rem;
  }

  .title-news-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;

    img {
      width: 5rem;
      height: 5rem;
      margin-left: 1.5rem;
    }
    .span-news {
      color: $primary400;
    }
  }
}

.button-news {
  height: 2rem;
  width: 6rem;
  margin: 0.4rem;
  cursor: pointer;
  background-color: #ffffff;
  transition: all 300ms;
  &:hover {
    background: #6936d7;
    color: #ffffff;
    border: none;
  }
}
