.accueil {
  display: flex;
  justify-content: center;
  background-color: white;
  height: 100vh;
  width: 100%;

  .section-switch-nav {
    height: 100%;
    width: 100%;
    overflow: scroll;

    // $test  == .aside-close;
    // @if $test == true {

    // }

    // @else {

    // }
  }
}
.sidebar-open {
  margin-left: 15rem;
}

.sidebar-closed {
  margin-left: 7rem;
}
