.row-table-planning {
  display: flex;
  flex-direction: row;
  width: 600px;
  height: 50px;
  padding-right: 1rem;
  padding-left: 1rem;
  border-bottom: 0.5px solid #f3f2ee;
  cursor: pointer;
  align-items: center;
  &:hover {
    background-color: #f3f3f3;
  }

  .props-title {
    width: 45%;
  }

  .props-week {
    width: 35%;
  }

  .props-created {
    display: flex;
    justify-content: flex-end;
    width: 25%;
  }
}

// SCSS FOR News PAGE (GLIDE)

.table-title-planning {
  display: flex;
  flex-direction: row;
  width: 600px;
  height: 40px;
  padding-right: 1rem;
  margin-top: 5rem;
  padding-left: 1rem;

  span {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.05em;

    color: #dedede;
  }

  .name {
    width: 42.5%;
  }

  .week {
    width: 42.5%;
  }

  .created {
    width: 14%;
  }
}
