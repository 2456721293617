.forget-password-section {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .header-password {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  .form-forget {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 10rem;
    width: 26rem;

    .form-forget-input {
      height: 3.6rem;
      outline: none;
      font-size: 1.2rem;
    }
    .submit-forget-reset {
      cursor: pointer;
      height: 3rem;
      text-transform: uppercase;
      font-size: 1.2rem;
      background: $primary500;
      color: $secondary100;
      transition: all 400ms;
      border: 1px solid black;

      &:hover {
        background: $secondary100;
        color: $primary500;
      }
    }
  }
}

.form-reset {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 20rem;
  width: 26rem;

  .form-forget-input {
    height: 3.6rem;
    outline: none;
    font-size: 1.2rem;
  }
  .submit-forget-reset {
    cursor: pointer;
    height: 3rem;
    text-transform: uppercase;
    font-size: 1.2rem;
    background: $primary500;
    color: $secondary100;
    transition: all 400ms;

    &:hover {
      background: $secondary100;
      color: $primary500;
    }
  }
}
