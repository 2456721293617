.create-section {
  height: 92vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: "Inter", sans-serif;
  background-color: #f4f5f7;
  border: 1px solid red;

  .title {
    align-items: center;
    text-align: center;
    flex-direction: row;
    margin-top: 2rem;
    h1 {
      font-size: 3rem;
      font-weight: 600;
      background: linear-gradient(0deg, #abb3fa 0%, #242424 100%);
      background-clip: border-box;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    h3 {
      font-size: 1.3rem;
    }

    h2 {
      margin-top: 3rem;
      margin-bottom: 3rem;
      font-weight: 500;
    }
  }
  input {
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
    height: 2rem;
    width: 18rem;
    color: gray;
    padding: 1.25rem;
  }
}
