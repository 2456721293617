.fourth-step {
  font-family: "Inter", sans-serif;
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  text-align: center;
  align-items: center;
  background: $secondary100;

  .iframe-style {
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
  }

  .four {
    color: #6935d7;
    margin-top: 3.75rem;
    font-size: 0.75rem;
  }

  .title-step4 {
    font-weight: 500;
    font-size: 36px;
  }
  .label-link {
    margin-top: 1rem;
  }
  label {
    display: flex;
    font-weight: 500;
    font-size: 0.875rem;

    color: #aaaaaa;
  }

  .input-fourth-step {
    border: none;
    width: 706px;
    height: 57px;
    padding: 1.25rem;
    margin-top: 1rem;
  }

  .input-fourth-step-title {
    border: none;
    width: 206px;
    height: 57px;
    padding: 1.25rem;
    margin-top: 1rem;
  }

  ::placeholder {
    color: gray;
    font-size: 0.875rem;
  }
  .span-delete {
    font-size: 14px;
    text-decoration: underline;
    margin-left: 10px;
  }
  .title-member-delete {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title-questionmark {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 0.8rem;
    margin-top: 1rem;

    .datastudio-link-title {
      margin-top: 3rem;
    }

    .link-documentation-step4 {
      text-decoration: none;
      color: black;
      text-decoration-line: underline;
    }
  }

  .title-questionmark2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
  }

  .hack {
    color: $secondary100;
  }

  .btn-delete {
    display: flex;
    cursor: pointer;
    justify-content: right;
    border: none;
    background-color: $secondary100;
    margin-top: 1rem;
  }

  .input-roadmap-link {
    border: none;
    width: 706px;
    height: 57px;
    padding: 1.25rem;
  }

  .error-msg-roadmap {
    display: flex;
    justify-content: center;
    color: $error400;
    font-size: 14px;
    margin-top: 1rem;
  }

  .input-datastudio {
    margin-top: 2rem;
  }

  input {
    border: none;
    width: 312px;
    height: 57px;
  }
  form {
    display: flex;
    justify-content: center;
  }
}
