.filelist-component {
  width: 100%;
  .file-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    gap: 2em;

    .file-card {
      min-height: 10rem;
      width: 12rem;
      display: flex;
      flex-direction: column;
      margin-top: 2rem;
      .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #bfc0c5;
        span {
          font-size: 0.7rem;
        }
      }
    }
  }
}

.card-body {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.6rem;
  img {
    width: 3rem;
    height: 3rem;
  }
}

.card-elements {
  border: 1px solid #f4f4f4;
  padding: 1rem;
  background: #fcfcfc;
}

.card-footer {
  font-size: 0.8rem;
  text-align: center;
  margin-top: 1rem;
}
.css-xl71ch {
  font-size: 1.6rem;
}
