.nav-accueil {
  font-family: "Inter", sans-serif;
  height: 7vh;

  border-bottom: 0.5px solid rgba(128, 128, 128, 0.096);
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: white;
  width: 100%;
  .back-arrow-nav {
    margin-left: 1.7rem;
    cursor: pointer;
  }
  span {
    margin-right: 1rem;
  }
  .logout-div {
    display: flex;
    align-items: center;

    cursor: pointer;
    .log-out-btn {
      margin-right: 2rem;
      cursor: pointer;
      transition: all 300ms;
      &:hover {
        color: $primary500;
      }
    }
  }
}

.arrow {
  transition: color 300ms;
  margin-top: 3.5px;
  &:hover {
    color: $primary500;
  }
}
