.dashboard-section {
  height: 100vh;
  font-family: "Inter", sans-serif;
}

/* .close-button {
  margin-left: 5rem;
  margin-top: 4rem;
  
}

body {
  background-color: #FAFAFA;
  font-family: "Inter-regular";
}

  .MuiStepConnector-line.MuiStepConnector-lineVertical {
    height: 160px;
  }

  .MuiStepIcon-root.MuiStepIcon-active {
    color: #B0B7FA;
  }


.title-step {
  font-family: Inter-Regular;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10rem;
  h1 {
    font-size: 20px;
    font-weight: 600;
    
  }
  h4 {
    font-size: 14px;
  }
}
 */
