body {
  background: $secondary100;
}
.third-step {
  font-family: "Inter", sans-serif;

  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  text-align: center;
  .file-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    border: 1px solid #f1f1f1;
    width: 200px;
    height: 140px;
    background-color: white;
    color: #505050;
  }

  .file-icon {
    width: 2rem;
  }

  .file-name {
    margin-top: 1rem;
    font-size: 12px;
  }

  .close-drag {
    position: relative;
    top: 22px;
    z-index: 2;
    background-color: white;
    border: 1px solid #e3e3e3;
    width: 28px;
    height: 28px;
    border-radius: 25px;
    margin-left: 181px;

    &:hover {
      background-color: #f1f1f1;
    }
  }

  .list-files {
    font-size: 12px;
    color: #505050;
  }

  .cross {
    margin-top: 5.5px;
    margin-left: 1px;
  }
}

.one {
  font-size: 0.75rem;
  margin-top: 3.75rem;
}
.title-step3 {
  font-weight: 500;
  font-size: 36px;
}

.skip {
  font-size: 0.875rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-decoration: underline;
}

.text-danger {
  color: $error400;
  font-size: 1rem;
}

.select-container-tags {
  width: 100%;
  margin-left: 1.5rem;
}

.file-display {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.file-card {
  height: 100%;
}

.rejected-files-title {
  display: flex;
  color: $primary500;
  font-size: 1rem;
  font-weight: 500;
  margin-top: 2rem;
}

.rejected-files-draganddrop {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 41rem;
  margin-top: 1rem;

  .rejected-files-description {
    color: $error400;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    span {
      display: flex;
      font-size: 0.875rem;
    }
  }
}

.title-uploaded {
  color: #6935d7;
  display: flex;
  justify-content: flex-start;
  width: 41rem;
  margin-top: 3.75rem;
  font-size: 1rem;
  font-weight: 500;
}
