.action-div {
  display: flex;
  flex-direction: row;
}

.remove-client-file {
  background: none;
  border: none;
}

.download-client-file {
  background: none;
  border: none;
}
