.client-file-section {
  min-height: 90%;
  display: flex;
  padding-bottom: 30vh;
  font-family: "Inter", sans-serif;
  background: white;
  flex-direction: column;
  align-items: center;

  .container-file-page {
    width: 52.188rem;
    align-items: center;
  }

  h1 {
    font-size: 2.25rem;
    margin-top: 12vh;
    font-weight: 600;
    letter-spacing: -0.3px;
    margin-bottom: 3.125rem;
  }

  h2 {
    display: flex;
    align-items: left;
    font-size: 1.25rem;
    font-weight: 600;
  }

  .h2-titles {
    margin-top: 2rem;
  }

  .SEO-first-title {
    display: flex;
    align-items: left;
    font-size: 1.5rem;
    font-weight: 500;
  }

  h3 {
    font-size: 0.875rem;
    color: #989898;
    margin-top: 0.5rem;
  }

  .css-zk88xw-singleValue {
    color: #fafafa;
  }

  .table-files {
    width: 55rem;
  }

  .client-file-container {
    min-width: 480px;
  }

  .table-title {
    display: flex;
    align-content: center;
    background-color: #f7f4fd;
    align-items: center;
    height: 3rem;
    border: 0.5px solid #e9e9e9;
    border-radius: 10px 10px 0px 0px;
    margin-top: 2rem;
    width: 100%;
    border-bottom: 0.5px solid #e9e9e9;
    .client-file-title-name {
      margin-left: 2rem;
      font-size: 0.813rem;
      width: 53%;
    }

    .client-file-title-mimetype {
      font-size: 0.813rem;
      width: 12%;
    }

    .client-file-title-date {
      font-size: 0.813rem;
      width: 12%;
    }

    .client-file-title-tag {
      font-size: 0.813rem;
      width: 19%;
    }

    .client-file-title-tag-admin {
      font-size: 0.813rem;
      width: 25%;
    }
  }

  .action-button-file {
    display: flex;

    justify-content: space-between;
    margin-right: 2rem;
    align-items: center;
    svg {
      cursor: pointer;
    }
  }

  .client-file-display {
    display: flex;
    justify-content: space-between;
    height: 3.5rem;
    border: 0.5px solid #e9e9e9;
    border-top: none;

    align-items: center;

    .client-file-title {
      width: 57%;
      margin-left: 2rem;
      padding-right: 10px;
      max-height: 3.5rem;
    }
    .client-file-mimetype {
      width: 13%;
    }

    .client-file-date {
      width: 13%;
    }

    .client-file-tag {
      width: 14%;
    }

    p {
      color: #4f4f4f;
      font-size: 0.813rem;
    }
  }

  .delete-btn {
    cursor: pointer;
    transition: all 300ms;
    color: $error400;
    &:hover {
      color: red;
    }
  }

  .file-icon {
    width: 2rem;

    .img {
      width: 2rem;
    }
  }

  .div-add-files {
    display: flex;
    justify-content: right;
    width: 100%;

    .modal-button-addfiles {
      display: flex;
      align-items: center;
      width: 110px;
      height: 35px;
      background: #f9f9fc;
      border: 0.1px solid #9145db;
      border-radius: 5px;
      padding: 0px;
      cursor: pointer;
      font-weight: 500;

      justify-content: center;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 13px;
      /* identical to box height */

      letter-spacing: -0.03em;

      color: $primary400;

      &:hover {
        background-color: $primary400;
        color: white;
        transition: all 0.5s ease-in-out;
      }
    }
  }

  .section-modal-addfiles {
    margin-right: 2rem;
    margin-left: 2rem;
    width: 50rem;

    .btn-upload-files {
      font-family: "Inter", sans-serif;
      font-size: 1rem;
      background-color: $primary500;
      color: white;
      width: 115px;
      height: 59px;
      border-radius: 45px;
      border: none;
      margin-top: 5rem;
      transition: all 700ms;
    }
  }
  .div-button-modal {
    display: flex;
    justify-content: center;
  }

  .close-modal {
    width: 100%;
    display: flex;
    justify-content: right;

    .close-modal-button {
      position: absolute;
      background-color: white;
      border: 1px solid #e3e3e3;
      width: 28px;
      height: 28px;
      margin-top: 1.5rem;
      border-radius: 25px;
      margin-left: 181px;
      margin-right: 2rem;

      &:hover {
        background-color: #f1f1f1;
      }
    }
  }

  .cross-modal {
    margin-top: 5.5px;
    margin-left: 5.99px;
  }
  @media (max-width: 1200px) {
    .table-files {
      width: 45rem;
    }

    .client-file-title-name {
      width: 73% !important;
    }

    .client-file-title-mimetype {
      width: 17% !important;
    }

    .client-file-title-date {
      width: 24% !important;
    }

    .client-file-title-tag {
      width: 35% !important;
    }

    .client-file-title {
      width: 70% !important;

      overflow-x: auto;
    }
    .client-file-mimetype {
      width: 17% !important;
    }

    .client-file-date {
      width: 24% !important;
    }

    .client-file-tag {
      width: 21.5% !important;
    }

    .client-file-title-tag-admin {
      width: 45% !important;
    }
  }

  @media (max-width: 1000px) {
    .table-files {
      width: 35rem;
    }

    .client-file-title-name {
      width: 70% !important;
    }

    .client-file-title-mimetype {
      width: 16% !important;
    }

    .client-file-title-date {
      display: none;
    }

    .client-file-title-tag {
      width: 41% !important;
    }

    .client-file-title {
      width: 70% !important;
    }
    .client-file-mimetype {
      width: 17% !important;
    }

    .client-file-date {
      display: none;
    }

    .client-file-tag {
      width: 28% !important;
    }

    .client-file-title-tag-admin {
      width: 54% !important;
    }
  }

  @media (max-width: 850px) {
    .table-files {
      width: 30rem;
    }

    .client-file-title-name {
      width: 55% !important;
    }

    .client-file-title-mimetype {
      width: 13% !important;
    }

    .client-file-title-tag {
      width: 31% !important;
    }

    .client-file-title-tag-admin {
      width: 42% !important;
    }

    .action-button-file {
      margin-right: 1rem;
    }
  }
}

//   table {
//     font-family: "Inter", sans-serif;
//     border-collapse: collapse;
//     width: 100%;
//   }

//   table td,
//   table th {
//     border-bottom: 1px solid #ddd;
//     padding: 8px;
//   }

//   table tr:nth-child(even) {
//     background-color: #f2f2f2;
//   }

//   table tr:hover {
//     background-color: #ddd;
//   }

//   table td {
//     font-size: 13px;
//   }

//   table th {
//     padding-top: 12px;
//     padding-bottom: 12px;
//     text-align: left;
//     font-size: 15px;
//     font-weight: 500;
//     color: black;
//   }
//   td img {
//     width: 2rem;
//   }
// }

.modal-confirm-delete {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .confirm-msg-delete {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    height: 4rem;
    padding-left: 3rem;
    padding-right: 3rem;
    span {
      color: $primary400;
      margin-left: 4px;
    }
  }
  .div-button-choice {
    display: flex;
    background-color: #f9f9fc;
    width: 100%;
    justify-content: space-around;
    height: 78px;
    align-items: center;
    .btn-yes {
      margin-left: 3rem;

      width: 108px;
      height: 33px;
      cursor: pointer;
      background: #f9f9fc;
      border: none;
      font-size: 1rem;
      box-sizing: border-box;
      border-radius: 6px;
      &:hover {
        background-color: $primary400;
        color: white;
      }
    }
    .btn-no {
      margin-right: 3rem;
      cursor: pointer;
      width: 108px;
      height: 33px;
      border: none;
      font-size: 1rem;
      background: #f9f9fc;

      box-sizing: border-box;
      border-radius: 6px;
      &:hover {
        background-color: $primary400;
        color: white;
      }
    }
  }
}

.no-file-div {
  width: 40rem;
  text-align: center;
  height: 100%;
  margin-top: 10rem;
  p {
    font-size: 1.7rem;
  }
}

.content-tabs {
  flex-grow: 1;

  .content {
    background: white;
    padding: 20px;
    width: 100%;
    height: 100%;
    display: none;

    .buttons-add-links-modal {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-top: 1.5rem;
    }

    .btn-add-links-modal {
      margin-top: 3rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px 21px;
      width: 115px;
      height: 38px;
      background: #f7f4fd;
      border-radius: 45px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 13px;
      letter-spacing: -0.03em;
      border: none;
      color: $primary500;

      &:hover {
        background-color: $primary400;
        color: white;
        transition: all 0.5s ease-in-out;
      }
    }
  }

  // .content h2 {
  //   padding: 0px 0 5px 0px;
  // }

  .content hr {
    width: 100px;
    height: 2px;
    background: #222;
    margin-bottom: 5px;
  }

  .content p {
    height: 100%;
  }

  .active-content {
    display: block;
  }
}

.bloc-tabs {
  display: flex;
  margin-left: 7.688rem;
  margin-top: 2.5rem;

  .tabs {
    padding: 16px;
    color: rgb(195, 195, 195);
    text-align: center;
    padding-right: 25px;
    padding-left: 25px;
    background: white;
    cursor: pointer;
    border-bottom: 1px solid rgb(195, 195, 195);
    box-sizing: content-box;
    position: relative;
    outline: none;
    font-weight: 500;
    font-size: 1rem;
    line-height: 19px;
    letter-spacing: -0.03em;
  }

  // .tabs:not(:last-child) {
  //   border-right: 1px solid rgba(0, 0, 0, 0.274);
  // }

  .active-tabs {
    background: white;
    border-bottom: 1px solid transparent;
    color: black;
  }

  .active-tabs::before {
    content: "";
    display: block;
    position: absolute;
    bottom: -1px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 2px);
    height: 3px;
    background: $primary400;
  }

  button {
    border: none;
  }
}
.h2-title-upload-links {
  font-weight: 500;
  font-size: 1rem;
  padding-left: 8rem;
  margin-top: 3rem;

  line-height: 19px;
  letter-spacing: -0.03em;

  color: #171717;
}

.upload-links-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-left: 10rem;
  margin-top: 2rem;
  padding-right: 10rem;

  form {
    display: flex;
    flex-direction: column;
  }

  .label-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.03em;

    color: black;
  }

  .label-link {
    margin-top: 1rem;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.03em;

    color: black;
  }

  .input-upload-links-title {
    width: 206px;
    height: 57px;
    padding: 1.25rem;
    margin-top: 1rem;
    background: #fefefe;
    border: 1px solid #eeeeee;
    box-sizing: border-box;
    border-radius: 10px;
  }

  .input-upload-links-link {
    background: #fefefe;
    border: 1px solid #eeeeee;
    box-sizing: border-box;
    border-radius: 10px;
    width: 506px;
    height: 57px;
    padding: 1.25rem;
    margin-top: 1rem;
  }
}

.close-modal-stp {
  width: 100%;
  display: flex;
  justify-content: right;
  // margin-right: 1rem;
  // margin-top: 0.5rem;
}
.cross-modal-stp {
  margin-left: 5px;
  margin-top: 5px;
}

.close-modal-button-stp {
  position: absolute;
  margin-right: 2.5rem;
  margin-top: 2rem;
  background-color: white;
  border: 1px solid #e3e3e3;
  width: 28px;
  height: 28px;
  border-radius: 25px;
  margin-left: 181px;

  &:hover {
    background-color: #f1f1f1;
  }
}
