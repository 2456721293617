.section-mysettings-consultant {
  background-color: white;
  align-items: center;
  font-family: "Inter", sans-serif;
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;
  .content-mysettings-consutant {
    min-height: 80vh;
    display: flex;
    width: 56.188rem;

    background: white;
    flex-direction: column;
    align-content: center;
  }

  .title-mysettings-consultant {
    margin-top: 12vh;
    font-size: 2.25rem;
    font-weight: 600;
    letter-spacing: -0.3px;
    margin-bottom: 3.125rem;
  }

  .icon-manage-client-confirm {
    cursor: pointer;
  }

  .change-logo-settings {
    color: #8b8b8b;
    font-size: 0.875rem;
    font-weight: 400;
    margin-top: 3rem;
    margin-bottom: 1.75rem;
  }

  .client-logo {
    width: 12.5rem;
    height: 12.5rem;
    border-radius: 0.625rem;
    object-fit: cover;
  }
  .custom-file-input::-webkit-file-upload-button {
    width: 0.2rem;
    visibility: hidden;
  }
  .custom-file-input::before {
    content: "Upload Photo";
    font-size: 1.125rem;

    &:hover {
      border-bottom: #676767;
      transition: 0.5s ease-in-out;
    }
  }

  .custom-file-input {
    color: #676767;
    position: relative;
    width: 25rem;
    height: 2rem;

    border-radius: 3px;
    padding: 5px 8px;

    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 600;
    font-size: 10pt;

    font-family: Inter-regular;
  }

  .client-logo-container {
    display: flex;

    flex-direction: row;
  }

  .button-update-settings {
    display: flex;
    justify-content: space-between;
    margin-top: 7rem;
    align-items: flex-end;
  }
  .button-description-container {
    margin-left: 1.188rem;
    width: 100%;

    .description-logo-settings {
      color: #7b7b7b;
      font-size: 0.75rem;

      width: 100%;
    }
  }

  .title-change-info-settings {
    color: #8b8b8b;
    font-size: 0.875rem;
    font-weight: 400;
    margin-top: 5rem;
    margin-bottom: 3.125rem;
  }

  .upload-logo-and-input {
    display: flex;
    align-items: center;
  }

  .button-update-settings {
    display: flex;
    justify-content: space-between;
    margin-top: 7.2rem;

    align-items: flex-end;
  }
  .input-name-email-settings {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .input-align-button-update {
      display: flex;
      align-items: center;
    }
    .input-name-settings {
      margin-right: 0.5rem;
    }
    input {
      border: 0.5px solid #e8e8e8;

      margin-top: 0.6rem;
      margin-bottom: 0.6rem;
      width: 19.5rem;
      height: 3.563rem;
      color: gray;
      border-radius: 10px;
      padding: 1.25rem;
    }
  }

  .icon-manage-client-update {
    cursor: pointer;
    margin-left: 1.563rem;
  }
  .icon-manage-client-confirm {
    cursor: pointer;
  }

  .btn-upload {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    width: 115px;
    height: 38px;
    background: #f7f4fd;
    border-radius: 45px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: -0.03em;
    border: none;
    color: $primary500;
    cursor: pointer;

    &:hover {
      background-color: $primary400;
      color: white;
      transition: all 0.5s ease-in-out;
    }
  }

  .btn-update {
    margin-left: 1.563rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    width: 120px;
    height: 40px;
    background: #f7f4fd;
    border-radius: 45px;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    border: none;
    color: $primary500;
    cursor: pointer;

    &:hover {
      background-color: $primary400;
      color: white;
      transition: all 0.5s ease-in-out;
    }
  }
}

.section-basic-info-settings {
  display: flex;
  flex-direction: column;
}
