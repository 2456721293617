.section-mysetting-client {
  min-height: 100vh;
  display: flex;

  background-color: white;
  flex-direction: column;
  align-items: center;

  .title-mysetting-client {
    display: flex;
    width: 100%;
    justify-content: left;
    font-size: 2.25rem;
    margin-top: 12vh;
    font-weight: 600;
    letter-spacing: -0.3px;
    margin-bottom: 3.125rem;
  }

  .content-mysetting-client {
    // min-height: 80vh;
    // display: flex;
    // // width: 52.188rem;
    // width: 100%;
    // background: white;
    // flex-direction: column;
    // align-content: center;

    input {
      border: 0.5px solid #e8e8e8;

      margin-top: 0.6rem;
      margin-bottom: 0.6rem;
      width: 19.5rem;
      height: 3.563rem;
      color: gray;
      border-radius: 10px;
      padding: 1.25rem;
    }
    form {
      margin-top: 2rem;
    }

    .container-password {
      display: flex;
      align-items: center;
      flex-direction: row;
    }

    .reset-password-client {
      color: #8b8b8b;
      margin-top: 2rem;
      margin-bottom: 1rem;
      font-size: 0.875rem;
      font-weight: 400;
    }

    .input-label-password {
      display: flex;
      flex-direction: column;
      margin-right: 1rem;
    }
  }

  .confirm-btn {
    margin-top: 1.2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    width: 115px;
    height: 38px;
    background: #f7f4fd;
    border-radius: 45px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: -0.03em;
    border: none;
    color: $primary500;
    cursor: pointer;

    &:hover {
      background-color: $primary400;
      color: white;
      transition: all 0.5s ease-in-out;
    }
  }
  // @media (max-width: 1050px) {
  // }

  @media (max-width: 1050px) {
    .content-mysetting-client {
      .container-password {
        display: initial;
        flex-direction: column;
        align-items: center;
      }
    }

    .input-label-password {
      display: flex;
      flex-direction: column;
      margin-right: 0rem !important;
      margin-bottom: 1.5rem;
    }

    .confirm-btn {
      margin-top: 0px !important;
    }

    .title-mysetting-client {
      display: flex;
      width: 15rem;
      justify-content: left;
    }
  }
}
