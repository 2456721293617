body {
  font-family: "Inter", sans-serif;
}
.title {
  align-items: center;
  text-align: center;
  flex-direction: row;
  margin-top: 2rem;
  border: 1px solid red;
  h1 {
    font-size: 3rem;
    border: 1px solid red;
    background: linear-gradient(0deg, #abb3fa 0%, #242424 100%);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  h3 {
    font-size: 1.3rem;
    border: 1px solid red;
  }

  h2 {
    margin-top: 3rem;
    margin-bottom: 3rem;
    font-family: "Inter", sans-serif;
    border: 1px solid red;
  }
}

.formulaire {
  display: flex;
  flex-direction: column;
  border: 1px solid red;
  align-items: center;
}

select {
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
  height: 2rem;
  width: 18rem;
  color: gray;
  border: 1px solid red;
}
