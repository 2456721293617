.client-section-dashboard {
  min-height: 100vh;
  display: flex;

  font-family: "Inter", sans-serif;
  background: white;
  flex-direction: column;
  align-items: center;

  .no-datastudio {
    position: fixed;
    top: 50%;
    font-size: 1.125rem;
    font-weight: 600;
  }

  h1 {
    font-size: 30px;
    margin-top: 5rem;
    font-weight: 500;
  }

  span {
    color: #989898;
  }

  .title-description-dashboards {
    margin-top: 4rem;
    span {
      font-size: 9px;
      margin-left: 2.5rem;

      color: gray;
    }

    h2 {
      font-size: 2.4rem;
      margin-left: 2.4rem;
    }
  }

  .select-dashboards {
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 312px;
    height: 57px;
  }

  .iframe {
    //width: 75%;
    width: 100%;
    height: 100vh;
    //height: 80rem;
    background: url("../../assets/loading.gif") 0px 0px no-repeat;
    background-position: center;
    //border-left: 1px solid #d8d8d8;
    //border-right: 1px solid #d8d8d8;
  }

  .mainBlock {
    display: flex;
    justify-content: center;
  }

  .logo-button-container {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .description-dashboards {
    .display-arrow-msg {
      display: flex;
      align-items: center;
      margin-left: 1.2rem;

      span {
        font-size: 0.8rem;
        color: #272727;
      }
    }

    height: 9rem;
    //width: 75%;
    width: 100%;
    background-color: white;
    border: 1px solid #d8d8d8;

    margin-bottom: 6rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .description-dashboards-top {
    display: flex;

    justify-content: space-between;

    span {
      font-size: 9px;
      margin-left: 2.5rem;
      margin-top: 4rem;
      color: gray;
    }

    h2 {
      font-size: 2.4rem;
      margin-left: 2.4rem;
    }

    height: 9rem;
    width: 100%; //width: 75%
    background-color: white;
    //border: 1px solid #d8d8d8;

    // margin-top: 6rem;
    //border-top-left-radius: 1rem;
    // border-top-right-radius: 1rem;

    .select-container {
      margin-right: 2.5rem;
      margin-top: 2.8rem;
    }
  }

  @media (max-width: 1000px) {
    .description-dashboards-top {
      flex-direction: column;
      height: 15rem;
    }

    .select-container {
      margin-right: 0rem !important;
      margin-top: 0rem !important;
      margin-left: 2.4rem;
    }
  }
}
