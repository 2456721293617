.secondstep-div {
  font-family: "Inter", sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #fafafa;

  .secondstep-para {
    margin-top: 3.75rem;
    font-size: 12px;
    color: $primary500;
  }
  .h1-title {
    font-size: 36px;
    width: 22rem;
    text-align: center;
    font-weight: 500;
  }

  .span-delete {
    font-size: 14px;
    text-decoration: underline;
    margin-left: 10px;
  }

  .container-select {
    margin-top: 40px;
  }

  .title-member-delete {
    display: flex;
    align-items: center;
    width: 5rem;
  }

  .select-div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 0.625rem;
    width: 42rem;
  }
}

// .css-1h0f56d-Control {
//   display: flex;
// }

// .css-1hb7zxy-IndicatorsContainer {
//   border-left: 1px solid #f1f1f1;
//   padding: 5px;
//   margin-right: 2px;
//   cursor: pointer;
// }
