.client-myteam-section {
  min-height: 100vh;
  display: flex;

  background-color: white;
  flex-direction: column;
  align-items: center;
  font-family: "Inter", sans-serif;

  .div-myteam-container {
  }

  .title-myteam-client {
    display: flex;
    width: 100%;
    justify-content: left;
    font-size: 2.25rem;
    margin-top: 12vh;
    font-weight: 600;
    letter-spacing: -0.3px;
    margin-bottom: 3.125rem;
  }

  .grid-myteam-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    column-gap: 70px;
    row-gap: 54px;
    margin-top: 4rem;
    .consultant-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 250px;
      height: 300px;
      img {
        object-fit: cover;
      }
      p {
        font-size: 1rem;
        font-weight: 400;
        color: #969696;
      }
      span {
        font-size: 13px;
        color: $primary400;
        font-weight: 500;
      }

      figure {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        img {
          width: 200px;
          height: 200px;
          border-radius: 100px;
          box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.25);
        }

        figcaption {
          font-weight: 500;
          font-size: 18px;
          margin-top: 15px;
          margin-bottom: 5px;
        }
      }

      .div-button-sendmail {
        button {
          // display: flex;
          // align-items: center;
          background: none;
          border: none;
          cursor: pointer;
        }
        .sendmail-icon {
          width: 1.5rem;
        }
      }
    }
  }
  @media (max-width: 1120px) {
    .consultant-container {
      figure {
        img {
          width: 150px !important;
          height: 150px !important;
        }
      }
    }

    .grid-myteam-container {
      column-gap: 7px !important;
      row-gap: 24px !important;
    }
    .title-myteam-client {
      display: flex;
      width: auto;
      font-size: 2.25rem;
    }
  }

  @media (max-width: 1050px) {
    .grid-myteam-container {
      grid-template-columns: repeat(2, 1fr) !important;
    }
  }
}
