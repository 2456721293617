.section-astronauts {
  background-color: white;
  align-items: center;
  font-family: "Inter", sans-serif;
  display: flex;
  flex-direction: column;

  .content-astronauts {
    margin-top: 3rem;
  }

  .grid-card-astronauts {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
  }

  .card-astronauts {
    width: 212px;
    height: 207px;
    background: #f8f8f8;
    border: 0.2px solid #ececec;
    box-sizing: border-box;
    border-radius: 5px;
    transition: transform 0.3s;
    &:hover {
      background-color: #d8d8d8;
      -ms-transform: scale(1.5); /* IE 9 */
      -webkit-transform: scale(1.5); /* Safari 3-8 */
      transform: scale(1.1);
    }

    .title-card-astro {
      padding-left: 10px;
      padding-bottom: 10px;
    }

    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;

      letter-spacing: -0.05em;

      color: #000000;
    }

    p {
      font-size: 12px;
      line-height: 15px;
      letter-spacing: -0.05em;

      color: #9145db;
    }
    img {
      object-fit: cover;
      width: 212px;
      height: 160px;

      &:hover {
        color: #c5c5c5;
      }
    }
  }
}
.container-astro-modal {
  background-image: url("../../../assets/background-lune.svg");
  background-repeat: no-repeat;

  .section-info-favorite {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .container-info-socialmedia-astro {
      display: flex;
      flex-direction: column;
    }
  }

  .img-astronaut {
    width: 270px;
    height: 215px;
    border: 7px solid #ffffff;
    box-sizing: border-box;
    margin-top: 3rem;
    object-fit: cover;
  }

  .container-info-astro {
    display: flex;
    margin-left: 6rem;
    width: 420px;
    height: 255px;
    justify-content: space-between;
  }

  .photo-div {
    margin-left: 6rem;
    display: flex;
    flex-direction: column;
  }

  .white-div {
    width: 270px;
    height: 40px;
    background-color: white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  label {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    color: #7e7e7e;
  }

  .astro-label-span {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    height: 50px;
    max-width: 250px;
  }

  .photo-name-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .name-job-title {
      margin-right: 7rem;
      margin-top: 5rem;
      text-align: right;
      h2 {
        font-weight: 500;
        font-size: 22px;
        line-height: 27px;

        color: #ffffff;
      }

      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;

        color: #aeb0b6;
      }
    }
  }

  .social-media {
    margin-left: 6rem;
    margin-top: 2.5rem;
    display: flex;
    align-items: center;

    .slack-link {
      margin-left: 4rem;
    }
  }
  .container-favorite-astro {
    border-radius: 20px;
    background: white;
    width: 480px;
    box-shadow: 0px 1px 2px rgba(163, 163, 163, 0.25);
    margin-right: 7rem;

    h3 {
      font-weight: 800;
      font-size: 16px;
      line-height: 19px;

      color: #9145db;
      text-align: center;
      margin-top: 1rem;
    }
  }
  .favorite-div {
    display: flex;
    justify-content: space-around;
    padding: 15px;

    .fav-title-logo {
      display: flex;
      align-items: center;
      color: $primary400;
      font-size: 12px;
      line-height: 13px;
      margin-top: 0.8rem;

      img {
        margin-right: 5px;
      }
    }

    .display-fav {
      font-size: 14px;
      line-height: 13px;
      margin-top: 5px;
    }
  }
}

.backgroundastro {
  width: 100%;
}
