.client-settings-section {
  min-height: 100vh;
  display: flex;
  width: 100%;
  background: white;
  flex-direction: column;
  align-items: center;
  font-family: "Inter", sans-serif;

  .title-client-logo-settings {
    margin-top: 6rem;
  }
  .title-settings-page {
    display: flex;
    flex-direction: column;
    width: 55rem;

    .description-title-settings {
      font-size: 0.875rem;
      color: #989898;
    }
  }

  h1 {
    font-size: 30px;
    margin-top: 5rem;
    font-weight: 500;
  }

  h2 {
    font-size: 1.125rem;
    font-weight: 500;
    margin-top: 6rem;
    margin-bottom: 40px;
  }

  h3 {
    font-size: 0.875rem;
    color: #989898;
    margin-top: 0.5rem;
  }

  h6 {
    color: #7b7b7b;
    font-size: 0.75rem;
  }

  .email-display {
    margin-left: 22rem;
  }

  .title-change-info-settings {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  .client-settings-container {
    width: 55rem;

    .client-logo {
      border-radius: 10px;
      width: 6rem;
    }
  }
  .input-name-email-settings {
    display: flex;
  }

  .input-settings-div {
    input {
      width: 7rem;
      height: 2.4rem;
      padding: 0;
      margin: 0;
    }
  }
  .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }

  .custom-file-input::before {
    content: "Upload Logo";
    color: white;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 7rem;
    height: 2.4rem;
    background: $primary500;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    padding: 0px;
    cursor: pointer;
    font-weight: 500;

    &:hover {
      background-color: $primary700;
      color: white;
    }
  }

  .btn-setting-active {
    color: $secondary100;
    background-color: $primary500;
  }
  .btn-client-settings-upload.btn-setting-active {
    color: $secondary100;
    background-color: $primary500;
  }

  .btn-client-settings-update {
    width: 7rem;
    height: 2.4rem;
    color: white;
    font-weight: 500;
    background-color: $primary500;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    justify-content: right;
    cursor: pointer;
    transition: all 500ms;

    &:hover {
      background-color: $primary700;
      color: white;
    }
  }

  .client-logo-container {
    display: flex;

    flex-direction: row;

    width: 100%;
  }

  .button-description-container {
    margin-left: 1.188rem;
    width: 100%;

    .description-logo-settings {
      color: #7b7b7b;
      font-size: 0.75rem;

      width: 100%;
    }
  }

  .button-update-settings {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-end;
  }

  .iframe-style-settings {
    display: flex;
    flex-direction: column;
  }

  .form-input-name-email {
    display: flex;
    margin-right: 1rem;

    .input-email-settings {
      margin-right: 0.5rem;
    }

    .input-name-settings {
      margin-right: 0.5rem;
    }
    input {
      border: none;

      margin-top: 0.6rem;
      margin-bottom: 0.6rem;
      width: 19.5rem;
      height: 3.563rem;
      color: gray;

      padding: 1.25rem;
    }
  }

  .btn-align-right {
    display: flex;
    justify-content: right;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .title-team-members-settings {
    margin-top: 2rem;
  }
  .select-div {
    display: flex;

    margin-top: 2rem;

    .member-role-settings {
      margin-left: 5rem;
    }
  }

  .display-datastudio-links {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 1.5rem;

    p {
      width: 100%;
      color: #616161;
      font-size: 13px;
    }
    .delete-link-btn {
      transition: all 300ms;
      cursor: pointer;
      &:hover {
        color: $primary500;
      }
    }
  }
  .name-role-settings {
    display: flex;
    flex-direction: row;
    margin-top: 1.5rem;

    color: #4f4f4f;
    width: 100%;
    font-size: 0.813rem;
    border-bottom: 0.5px solid #606060;
    justify-content: space-between;

    .name-settings {
      width: 30%;
      font-size: 0.813rem;
      margin-bottom: 1.5rem;
      letter-spacing: -0.03em;
    }
    .role-settings {
      width: 48%;
      font-size: 0.813rem;
      margin-bottom: 1.5rem;
      letter-spacing: -0.03em;
    }
  }

  .display-name-email-settings {
    display: flex;
    width: 10rem;
    font-size: 1rem;
  }

  .ReactModal__Content {
    inset: 150px;
  }
}

.delete-consultant-btn {
  transition: all 300ms;
  cursor: pointer;
  &:hover {
    color: $primary500;
  }
}

.btn-delete-entity {
  margin-bottom: 4rem;
  color: white;
  background: $error400;
  cursor: pointer;
  width: 168px;
  height: 46px;
  border: none;
  border-radius: 25px;
  font-size: 1rem;
  &:hover {
    opacity: 0.9;
  }
}

.header-modal-delete-entity {
  display: flex;
  justify-content: space-around;

  margin-bottom: 2rem;

  width: 100%;

  h3 {
    color: $primary500;
    width: 100%;
    margin-top: 4rem;
  }
}

.confirm-delete-entity-msg {
  margin-top: 3rem;
  font-weight: 500;
}

.input-modal-delete-entity {
  width: 19.5rem;
  height: 3.563rem;
  padding: 1.25rem;
  color: gray;
  margin-top: 2rem;
  border-radius: 5px;
  border: 0.2px solid rgb(233, 233, 233);
}
.input-delete-btn-modal-div {
  display: flex;
  flex-direction: column;

  .div-last-delete-btn {
    display: flex;
    justify-content: center;
    margin-top: 4rem;
  }
  .last-delete-btn-entity {
    width: 29.875rem;
    color: white;
    background: $error400;
    cursor: pointer;
    border-radius: 25px;
    font-size: 1rem;
    height: 2.875rem;
    border: none;
  }
}
