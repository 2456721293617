.dashboards-manage-client {
  min-height: 100vh;
  display: flex;

  width: 52.188rem;
  font-family: "Inter", sans-serif;
  background: white;
  flex-direction: column;
  align-content: center;

  .title-dashboards-mc {
    // color: #8b8b8b;
    // font-size: 14px;
    font-weight: 400;
    margin-top: 48px;
    margin-bottom: 28px;
    font-size: 100%;
    font-weight: normal;
  }
  .display-datastudio-links {
    display: flex;
    flex-direction: row;
    align-items: center;

    width: 100%;

    margin-bottom: 1.5rem;
    justify-content: space-between;
    .datastudio-links {
      width: 43.25rem;
      background: #f9f9fc;

      border: 0.1px solid #f0f0f0;
      border-radius: 3px;

      padding: 16px 16px 16px 12px;

      a {
        text-decoration: none;
        color: #616161;
        font-size: 13px;

        &:hover {
          color: $primary400;
        }
      }
    }
    p {
      width: 100%;
      color: #616161;
      font-size: 13px;
    }
    .delete-link-btn {
      transition: all 300ms;
      cursor: pointer;
      &:hover {
        color: $primary500;
      }
    }
  }
  .input-label-dashboard-title {
    display: flex;
    flex-direction: column;
  }
  .title-add-datastudio-mc {
    font-size: 100%;
    font-weight: normal;
    margin-top: 40px;
  }

  .input-add-dashboard-title {
    border: none;
    width: 206px;
    height: 57px;
    padding: 1.25rem;
    border: 0.5px solid #ececee;
    border-radius: 10px;
  }

  .label-datastudio-link-mc {
    margin-bottom: 18px;
    margin-top: 35px;
    color: #8b8b8b;
    font-size: 14px;
    font-weight: 400;
  }

  .title-datastudios {
    font-size: 14px;
    width: fit-content;
    padding: 8px;
  }

  .input-fourth-step {
    border: 0.5px solid #ececee;
    border-radius: 10px;
    width: 43.25rem;
    height: 57px;
    padding: 1.25rem;
  }

  .title-roadmap-mc {
    font-size: 100%;
    font-weight: normal;
    margin-top: 3rem;
    margin-bottom: 25px;
  }

  .iframe-style-settings {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .btn-dashboard-page {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 21px;
    width: 115px;
    height: 38px;
    background: #f7f4fd;
    border-radius: 45px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: -0.03em;
    border: none;
    color: $primary500;

    &:hover {
      background-color: $primary400;
      color: white;
      transition: all 0.5s ease-in-out;
    }
  }

  .section-roadmap-links {
    width: 100%;

    .div-title-input {
      display: flex;
      flex-direction: column;
    }

    .div-roadmap-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
