.coming-soon {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  background-image: url("../../assets/cape-canaveral.jpg");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  width: 100%;
  z-index: 1;
  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.285);
  }

  .coming-soon__heading1 {
    color: aliceblue;
    z-index: 22;
    background: rgba(0, 0, 0, 0.5);
    font-family: "Inter", sans-serif;
    letter-spacing: 1px;
    margin: auto;
    padding: 30px;
  }
}

.iframe-notion {
  width: 100%;
  height: 100vh;
}
