.btn-next {
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  background-color: #c1aeeb;
  color: white;
  width: 115px;
  height: 59px;
  border-radius: 45px;
  border: none;
  margin-top: 5rem;
  transition: all 700ms;
  cursor: pointer;
  .active {
    background-color: #6935d7;
  }
}

.btn-next-active {
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  background-color: #6935d7;
  color: white;
  width: 115px;
  height: 59px;
  border-radius: 45px;
  border: none;
  margin-top: 5rem;
  cursor: pointer;
}
